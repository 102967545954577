import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Formik, Field } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  authSelectorGetData,
  authSliceLogoutThunk,
} from "../../redux/slices/authSlice";
import {
  filterCompaniesSliceGetDataThunk,
  filterCompaniesSliceUpdateCompanie,
} from "../../redux/slices/filterCompaniesSlice";
import { postCustomFieldCompanyThunk } from "../../redux/slices/customFieldsSlice";
import { AutoCompleteStyled } from "../ui/AutoCompleteStyled";
import { InputStyled } from "../ui/InputStyled";
import { ModalStyled } from "../ui/ModalStyled";
import { getFullValidationSchema } from "../../utils/schemas";
import "../css/customFields.css";

export const CompanyFilterModalEdit = ({
  show,
  onShow,
  data,
  customFields,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modifiedFields, setModifiedFields] = useState([]);

  const { user } = useSelector(authSelectorGetData);

  const domain = user.environment_domain.split(",");

  const handleCustomFieldChange = (field, value, customFieldId) => {
    setModifiedFields((prevFields) => {
      const existingFieldIndex = prevFields.findIndex(
        (f) => f.custom_field_id === customFieldId
      );
      const newField = {
        custom_field_id: customFieldId,
        field_name: field,
        value,
      };
      if (existingFieldIndex >= 0) {
        const updatedFields = [...prevFields];
        updatedFields[existingFieldIndex] = newField;
        return updatedFields;
      }
      return [...prevFields, newField];
    });
  };

  useEffect(() => {
    if (show) {
      setModifiedFields([]);
    }
  }, [show]);

  const handleOnSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const formattedValues = {
      ...values,
      executive: values.executive.join(","),
      group: values.group.join(","),
      branch: values.branch.join(","),
      mails: [values.mails1.trim(), values.mails2.trim()],
      custom_fields: modifiedFields.map((field) => ({
        entity_id: data.id,
        custom_field_id: field.custom_field_id || null,
        value: values[field.field_name],
      })),
    };

    try {
      const validationSchema = getFullValidationSchema(customFields, domain);
      await validationSchema.validate({
        ...values,
        mails1: values.mails1,
        mails2: values.mails2,
      });
      await dispatch(
        filterCompaniesSliceUpdateCompanie({
          ...values,
          executive: values.executive.join(","),
          group: values.group.join(","),
          branch: values.branch.join(","),
          mails: [values.mails1.trim(), values.mails2.trim()],
        })
      ).unwrap();
      if (formattedValues.custom_fields.length > 0) {
        await dispatch(
          postCustomFieldCompanyThunk({
            custom_fields: formattedValues.custom_fields,
          })
        ).unwrap();
      }
      onShow();
      await dispatch(filterCompaniesSliceGetDataThunk()).unwrap();
    } catch (err) {
      if (err?.code === 401) {
        setTimeout(() => {
          dispatch(authSliceLogoutThunk()).finally(() => {
            history.replace("/");
          });
        }, 3000);
      } else {
        alert(err.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    id: data?.id || "",
    business_name: data?.business_name || "",
    executive: data?.executive || "",
    group: data?.group || "",
    branch: data?.branch || "",
    rut: data?.rut || "",
    mails1: data?.mails?.[0] || "",
    mails2: data?.mails?.[1] || "",
    last_mail1: data?.mails?.[0] || "",
    last_mail2: data?.mails?.[1] || "",
  };

  if (data?.custom_field) {
    data.custom_field.forEach((field) => {
      if (field.field_type === "list") {
        initialValues[field.field_name] = field.field_value || "";
      } else if (field.field_value === "on") {
        initialValues[field.field_name] = true;
      } else {
        initialValues[field.field_name] = field.field_value;
      }
    });
  } else {
    customFields.forEach((field) => {
      if (field.field_type === "list") {
        initialValues[field.field_name] = "";
      } else if (field.field_type === "checkbox") {
        initialValues[field.field_name] = false;
      } else {
        initialValues[field.field_name] = "";
      }
    });
  }

  const getFieldOptions = (field) => {
    if (field.field_type_list_values) {
      return field.field_type_list_values.split(",").map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ));
    }
    return null;
  };

  return (
    <>
      <ModalStyled show={show} onShow={() => onShow()} title="Editar empresa">
        {data ? (
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
            {({ isSubmitting, handleSubmit, values, setFieldValue }) => (
              <>
                <DialogContent
                  dividers={true}
                  sx={{
                    px: 3,
                    py: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <InputStyled
                      fullWidth
                      disabled={isSubmitting}
                      name="business_name"
                      label="Nombre de la empresa"
                      placeholder=""
                      inputProps={{
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                    <Divider />
                    <InputStyled
                      fullWidth
                      disabled={isSubmitting}
                      name="mails1"
                      label="Primer Correo (opcional)"
                      placeholder="primer.correo@example.com"
                      inputProps={{
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                    <InputStyled
                      fullWidth
                      disabled={isSubmitting}
                      name="mails2"
                      label="Segundo Correo (opcional)"
                      placeholder="segundo.correo@example.com"
                      inputProps={{
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                    <Divider />
                    <AutoCompleteStyled
                      disabled={isSubmitting}
                      fullWidth
                      freeSolo
                      name={"executive"}
                      label="Filtro de ejecutivo (opcional)"
                      placeholder="Presiona enter para agregar"
                    />
                    <AutoCompleteStyled
                      disabled={isSubmitting}
                      fullWidth
                      freeSolo
                      name={"branch"}
                      label="Filtro de sucursal (opcional)"
                      placeholder="Presiona enter para agregar"
                    />
                    <AutoCompleteStyled
                      disabled={isSubmitting}
                      fullWidth
                      freeSolo
                      name={"group"}
                      label="Filtro de grupo (opcional)"
                      placeholder="Presiona enter para agregar"
                    />
                    <Divider />
                    {customFields.map((field) => (
                      <Box key={field.id} sx={{ width: "100%" }}>
                        {field.field_type === "text" && (
                          <Field
                            as={TextField}
                            fullWidth
                            disabled={isSubmitting}
                            name={field.field_name}
                            label={field.field_name}
                            placeholder=""
                            variant="outlined"
                            InputProps={{
                              autoComplete: "off",
                            }}
                            onChange={(e) => {
                              setFieldValue(field.field_name, e.target.value);
                              handleCustomFieldChange(
                                field.field_name,
                                e.target.value,
                                field.id
                              );
                            }}
                          />
                        )}
                        {field.field_type === "number" && (
                          <TextField
                            fullWidth
                            disabled={isSubmitting}
                            name={field.field_name}
                            label={field.field_name}
                            type="number"
                            placeholder=""
                            variant="outlined"
                            InputProps={{
                              autoComplete: "off",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                            value={values[field.field_name] || ""}
                            onChange={(e) => {
                              setFieldValue(field.field_name, e.target.value);
                              handleCustomFieldChange(
                                field.field_name,
                                e.target.value,
                                field.id
                              );
                            }}
                          />
                        )}
                        {field.field_type === "list" && (
                          <FormControl fullWidth>
                            <InputLabel>{field.field_name}</InputLabel>
                            <Select
                              name={field.field_name}
                              value={values[field.field_name] || ""}
                              label={field.field_name}
                              onChange={(e) => {
                                setFieldValue(field.field_name, e.target.value);
                                handleCustomFieldChange(
                                  field.field_name,
                                  e.target.value,
                                  field.id
                                );
                              }}
                            >
                              <MenuItem value=""></MenuItem>
                              {getFieldOptions(field)}
                            </Select>
                          </FormControl>
                        )}
                        {field.field_type === "checkbox" && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values[field.field_name]}
                                onChange={(e) => {
                                  setFieldValue(
                                    field.field_name,
                                    e.target.checked
                                  );
                                  handleCustomFieldChange(
                                    field.field_name,
                                    e.target.checked ? "on" : "off",
                                    field.id
                                  );
                                }}
                                disabled={isSubmitting}
                                sx={{
                                  color: "text.purple",
                                  "&.Mui-checked": {
                                    color: "primary.main",
                                  },
                                }}
                              />
                            }
                            label={field.field_name}
                            labelPlacement="start"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                color: "text.purple",
                                fontSize: "large",
                              },
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Box className="dialogActionsBox">
                    <Button
                      disabled={isSubmitting}
                      color="error"
                      type="button"
                      onClick={() => onShow()}
                    >
                      Cancelar
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Editar empresa
                    </Button>
                  </Box>
                </DialogActions>
              </>
            )}
          </Formik>
        ) : (
          <Box
            className="modalBox"
            sx={{
              bgcolor: "background.primary",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </ModalStyled>
    </>
  );
};
