import AWS from "aws-sdk";

export const S3DocumentService = {
  getManualsFromS3: async () => {
    try {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        region: process.env.REACT_APP_S3_AWS_REGION,
      });

      const s3 = new AWS.S3();

      const response = await s3
        .listObjectsV2({
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        })
        .promise();

      const manuals = response.Contents.filter((obj) =>
        obj.Key.endsWith(".pdf")
      );

      return manuals;
    } catch (error) {
      console.error("Error al obtener manuales desde S3:", error);
      return [];
    }
  },
};
