import axios from "axios";

const apiAuthUrl =
  process.env.REACT_APP_API_AUTH_URL || "https://api.fapro.app";

// const localStorage.getItem('fapro_token') = localStorage.getItem('fapro_token')

export const filterCompaniesApiGetData = async ({ signal }) => {
  try {
    const res = await axios({
      method: "get",
      url: `${apiAuthUrl}/api/v1.0/companies/filters`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
      },
      signal,
    });

    const data = await res.data;

    if (data.code === 1) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const filterCompaniesApiDataExport = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${apiAuthUrl}/api/v1.0/companies/filters/export`,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
      },
    });

    const data = await res.data;

    let url = window.URL.createObjectURL(new Blob([data]));

    return {
      fapro: {
        success: true,
        code: 200,
        message: "Datos exportados correctamente",
        status: "success",
        data: url,
      },
    };
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: null,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const filterCompaniesApiDataImport = async ({ file, customFields }) => {
  try {
    let formData = new FormData();
    formData.append('file', file, 'archivo.xlsx');

    const res = await axios(
        {
            method:'post', 
            url:`${apiAuthUrl}/api/v1.0/companies-filter`, 
            data: formData,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('fapro_token')}`,
                Accept: 'application/json', 
                'Content-Type': 'multipart/form-data',
            }
        }
    )

    const data = await res.data;

    if (data.code === 200) {
      return {
        fapro: {
          success: true,
          code: data.code,
          message: data.message,
          status: data.status,
          data: data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: data.code,
          message: data.message,
          status: data.status,
          data: null,
        },
      };
    }
  } catch (err) {
    if (err.response) {
      return {
        fapro: {
          success: false,
          code: err.response.data.code,
          message: err.response.data.message,
          status: err.response.data.status,
          data: err.response.data.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: 500,
          message: err.message || "Opps! Algo salió mal, intente más tarde.",
          status: "error",
          data: null,
        },
      };
    }
  }
};

export const filterCompaniesApiUpdateCompanie = async ({ id, ...rest }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${apiAuthUrl}/api/v1.1/companies/update/${id}`,
      data: {
        ...rest,
        id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
      },
    });

    const body = await res.data;

    if (body.code === 1) {
      return {
        fapro: {
          success: true,
          code: body.code,
          message: body.message,
          status: body.status,
          data: body.data,
        },
      };
    } else {
      return {
        fapro: {
          success: false,
          code: body.code,
          message: body.message,
          status: body.status,
          data: null,
        },
      };
    }
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};
