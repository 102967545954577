import axios from "axios";
import { taxSliceSetMessageError } from "../../redux/slices/taxSlice";

export const handleDownloadEERR = async (
  companyId,
  companyName,
  dispatch,
  setLoadingMessage
) => {
  const apiUrl = process.env.REACT_APP_API_AUTH_URL;
  const apiUrlEERR = apiUrl + "/api/v1.0/state_result";

  const requestData = {
    company_id: companyId,
    years_ago: 5,
    format_file: "xlsx",
  };
  const config = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
    },
    responseType: "blob",
  };

  try {
    setLoadingMessage(
      "Tu solicitud está siendo procesada, esto tomará solo unos segundos"
    );
    const response = await axios.post(apiUrlEERR, requestData, config);
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `EERR_${companyName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error en la respuesta:", error.response.status);
    let errorMessage = "Error al generar el archivo.";
    if (error.response && error.response.status === 400) {
      errorMessage =
        "No existen datos de estados resultados para el formato especificado.";
    }
    dispatch(taxSliceSetMessageError({ message: errorMessage, type: "error" }));
  } finally {
    setLoadingMessage(null);
  }
};

export const handleDownloadDocuments = async (
  rut,
  dispatch,
  setLoadingMessage
) => {
  const apiUrl =
    process.env.REACT_APP_ONBOARDING_SII_URL +
    "/api/v1/onboarding-docs?rut=" +
    rut;

  const config = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("fapro_token")}`,
    },
    responseType: "blob",
  };
  try {
    setLoadingMessage(
      "Tu solicitud está siendo procesada, esto tomará solo unos segundos"
    );
    const response = await axios.get(apiUrl, config);
    if (response.status === 200) {
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", `documentos_rut_${rut}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      const errorMessage =
        response.data.errors && response.data.errors.length > 0
          ? response.data.errors[0].detail
          : "Error al descargar los documentos.";
      dispatch(
        taxSliceSetMessageError({
          message: errorMessage,
          type: "error",
        })
      );
    }
  } catch (error) {
    let errorMessage = "Error al descargar los documentos.";
    if (error.response && error.response.status === 409) {
      errorMessage = "El proceso de onboarding no esta completado";
    }
    dispatch(
      taxSliceSetMessageError({
        message: errorMessage,
        type: "error",
      })
    );
  } finally {
    setLoadingMessage(null);
  }
};
