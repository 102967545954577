import React, { useEffect, useState } from "react";
import { ModalStyled } from "../ui/ModalStyled";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  onboardingSelectorGetData,
  onboardingSelectorGetDataStatus,
  onboardingSelectorGetShowModal,
  onboardingSliceGetDataThunk,
  onboardingSliceSetShowModal,
} from "../../redux/slices/onboardingSlice";
import { S3DocumentService } from "../../utils/S3DocumentService";
import { OldStyleDialogContent, NewStyleDialogContent } from "./DialogContent";
import { useTheme } from "../../context/ThemeContext";

export const OnboardingModal = () => {
  const dispatch = useDispatch();
  const { isNewMode } = useTheme();

  const data = useSelector(onboardingSelectorGetData);
  const show = useSelector(onboardingSelectorGetShowModal);
  const status = useSelector(onboardingSelectorGetDataStatus);
  const [tabs, setTabs] = useState(0);
  const [manuals, setManuals] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const bucket = process.env.REACT_APP_S3_BUCKET_NAME;

  const handleChangeTabs = (event, newValue) => {
    setTabs(newValue);
  };

  useEffect(() => {
    dispatch(onboardingSliceGetDataThunk());
  }, [dispatch]);

  useEffect(() => {
    const fetchManuals = async () => {
      try {
        const manualsFromS3 = await S3DocumentService.getManualsFromS3();
        setManuals(manualsFromS3);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al obtener manuales desde S3:", error);
        setIsLoading(false);
      }
    };

    fetchManuals();
  }, []);

  const handleHidden = () => {
    dispatch(onboardingSliceSetShowModal({ show: false }));
  };

  return (
    <ModalStyled
      show={show}
      onShow={handleHidden}
      maxWidth="md"
      title=" "
      isNewMode={isNewMode}
    >
      <DialogTitle bgcolor={"background.terciary"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
            height: "100%",
          }}
          px={1}
          py={1}
        >
          <Typography
            variant="h3"
            gutterBottom
            component="h3"
            fontSize={26}
            width={{
              xs: "100%",
              sm: "95%",
              md: "90%",
              lg: "85%",
            }}
            fontWeight={600}
            color={"text.primary"}
            textAlign={"center"}
          >
            ¡Comencemos a usar nuestra plataforma!
          </Typography>
          <Typography
            textAlign={"center"}
            width={{
              xs: "100%",
              sm: "95%",
              md: "90%",
              lg: "85%",
            }}
          >
            En este proceso de onboarding, te enseñaremos cómo utilizar las
            herramientas y funcionalidades de nuestra plataforma para que puedas
            obtener los mejores resultados.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{
          px: 4,
          py: 3,
          bgcolor: "background.terciary",
        }}
      >
        {isNewMode ? (
          <NewStyleDialogContent
            status={status}
            manuals={manuals}
            data={data}
            bucket={bucket}
            tabs={tabs}
            handleChangeTabs={handleChangeTabs}
          />
        ) : (
          <OldStyleDialogContent
            status={status}
            manuals={manuals}
            data={data}
            bucket={bucket}
            tabs={tabs}
            handleChangeTabs={handleChangeTabs}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          bgcolor: "background.terciary",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
            height: "100%",
            px: 2,
            py: 1,
            bgcolor: "transparent",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleHidden}>
            Cerrar
          </Button>
        </Box>
      </DialogActions>
    </ModalStyled>
  );
};
