import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import FaproContext from "../context/faproContext";
import InitialState from "../hooks/useInitialState";
import Login from "../container/login/container";
import Recovery from "../container/recovery/container";
import NewPass from "../container/newPass/container";
import Dashboard from "../container/dashboard/container";
import Link from "../container/link/container";
import Certificate from "../container/certificate/container";
import Tax from "../container/tax/container";
import CompanyFilter from "../container/companyFilter/container";
import Onboarding from "../container/onboarding/container";
import Iva from "../container/iva/container";
import Account from "../container/accounts/container";
import AccountList from "../container/accountList/container";
import MassiveAccounts from "../container/massiveAccounts/container";
import Downloads from "../container/downloads/container";
import Emails from "../container/emails/container";
import Configs from "../container/configs/container";
import WebUsage from "../container/webUsage/container";
import CustomFields from "../container/customFields/container";
import {
  authSelectorGetData,
  authSliceRenewTokenThunk,
} from "../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import RouteController from "./RouteController";
import { uiSelectorGetLoading } from "../redux/slices/uiSlice";
import Loader from "../components/commons/Loader";
import PublicLayoutStyled from "../components/ui/PublicLayoutStyled";
import PrivateLayoutStyled from "../components/commons/PrivateLayoutStyled";
import ProfileUser from "../container/profile/container";
import ChangePassword from "../container/change-password/container";
import DebtorsWhiteList from "../container/debtorListsWhite/container";
import DebtorsBlackList from "../container/debtorListsBlack/container";

const {
  REACT_APP_REPORT,
  REACT_APP_LINK_RUT,
  REACT_APP_LINK_CERTIFICATE,
  REACT_APP_IVA,
  REACT_APP_TAX_FOLDER,
  REACT_APP_CREATE_ACCOUNT,
  REACT_APP_LIST_ACCOUNT,
  REACT_APP_EMAIL,
  REACT_APP_DOWNLOAD,
  REACT_APP_WEB_USAGE,
  REACT_APP_CREATE_MASSIVE_ACCOUNTS,
  REACT_APP_DEBTORS_LIST,
  REACT_APP_CONFIG,
  REACT_APP_CUSTOM_FIELDS,
} = process.env;

const AppRouter = () => {
  const state = { ...InitialState() };

  const loading = useSelector(uiSelectorGetLoading);
  const data = useSelector(authSelectorGetData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!localStorage.getItem("fapro_token")) {
      dispatch(authSliceRenewTokenThunk());
    }
  }, [dispatch]);

  const permissionsValidations = (obj) => {
    if (!obj.length) {
      message.error(
        "La cuenta no cuenta con permisos para acceder, comuníquese con el administrador del sistema para solucionar el inconveniente.",
        4
      );
    }
  };

  useEffect(() => {
    if (!!data) {
      permissionsValidations(data.user.permissions);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <FaproContext.Provider value={state}>
      <Router>
        <Route exact path="/password/reset/:id">
          <RouteController requiredAuth={false}>
            <PublicLayoutStyled>
              <NewPass />
            </PublicLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/">
          <RouteController requiredAuth={false}>
            <PublicLayoutStyled>
              <Login />
            </PublicLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/recovery">
          <RouteController requiredAuth={false}>
            <PublicLayoutStyled>
              <Recovery />
            </PublicLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_REPORT === "1" ? <Dashboard /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/change-password">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              <ChangePassword />
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/profile">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              <ProfileUser />
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/link">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_LINK_RUT === "1" ? <Link /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/certificate">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_LINK_CERTIFICATE === "1" ? <Certificate /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/tax">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_TAX_FOLDER === "1" ? <Tax /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>

        <Route exact path="/dashboard/filter">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_TAX_FOLDER === "1" ? <CompanyFilter /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>

        <Route exact path="/dashboard/debtors/white">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_DEBTORS_LIST === "1" ? <DebtorsWhiteList /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/debtors/black">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_DEBTORS_LIST === "1" ? <DebtorsBlackList /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>

        <Route exact path="/onboarding">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              <Onboarding />
            </PrivateLayoutStyled>
          </RouteController>
        </Route>

        <Route exact path="/dashboard/iva">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_IVA === "1" ? <Iva /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/accounts">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_CREATE_ACCOUNT === "1" ? <Account /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/massive-accounts">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_CREATE_MASSIVE_ACCOUNTS === "1" ? (
                <MassiveAccounts />
              ) : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>

        <Route exact path="/dashboard/accounts-list">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_LIST_ACCOUNT === "1" ? <AccountList /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/web-usage">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_WEB_USAGE === "1" ? <WebUsage /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/downloads">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_DOWNLOAD === "1" ? <Downloads /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/emails">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_EMAIL === "1" ? <Emails /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/configs">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_CONFIG === "1" ? <Configs /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route exact path="/dashboard/custom_fields">
          <RouteController requiredAuth={true}>
            <PrivateLayoutStyled>
              {REACT_APP_CUSTOM_FIELDS === "1" ? <CustomFields /> : null}
            </PrivateLayoutStyled>
          </RouteController>
        </Route>
        <Route path="*" exact>
          {!!data ? <Redirect to="/dashboard" /> : null}
        </Route>
      </Router>
    </FaproContext.Provider>
  );
};

export default AppRouter;
