import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  accountSelectorGetDataPermissions,
  accountSelectorGetUserEdit,
  accountSliceClearUserEdit,
  accountSlicePutDataUpdateAccountThunk,
} from "../../redux/slices/accountSlice";
import { authSliceLogoutThunk } from "../../redux/slices/authSlice";
import { editAccountSchema } from "../../utils/schemas";
import { AutoCompleteStyled } from "../ui/AutoCompleteStyled";
import { CheckboxStyled } from "../ui/CheckboxStyled";
import { InputStyled } from "../ui/InputStyled";
import { ModalStyled } from "../ui/ModalStyled";

export const ModalEditAccount = ({ show, onShow }) => {
  const dispatch = useDispatch();

  const permissions = useSelector(accountSelectorGetDataPermissions);
  const currentUser = useSelector(accountSelectorGetUserEdit);

  const currentPermissions = currentUser?.permissions.map(
    (prev) => prev.codename
  );

  const history = useHistory();

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      accountSlicePutDataUpdateAccountThunk({
        ...values,
        permissions: values.permissions
          .filter((prev) => prev.checked && prev.value !== "all")
          .map((prev) => prev.value),
        executive: values.executive.join(","),
        branch: values.branch.join(","),
        group: values.group.join(","),
      })
    )
      .unwrap()
      .then((res) => {
        if (typeof res === "string") {
        } else {
          dispatch(accountSliceClearUserEdit());
          onShow(null);
        }
      })
      .catch((err) => {
        if (err?.code === 401) {
          dispatch(accountSliceClearUserEdit());
          onShow(null);
          setTimeout(() => {
            dispatch(authSliceLogoutThunk()).finally(() => {
              history.replace("/");
            });
          }, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <ModalStyled show={show} onShow={() => onShow(null)} title="Editar Usuario">
      {currentUser ? (
        <Formik
          initialValues={{
            id: currentUser?.id,
            phone_number: currentUser?.phone_number,
            name: currentUser?.first_name,
            lastName: currentUser?.last_name,
            permissions: [
              {
                label: "Todos los permisos",
                value: "all",
                checked: permissions
                  .filter((prev) => prev.codename !== "view_comercialalerts")
                  .map((prev) => ({
                    value: prev.codename,
                    label: prev.name,
                    checked: currentPermissions.includes(prev.codename),
                  }))
                  .every((prev) => prev.checked),
              },
              ...permissions
                .filter((prev) => prev.codename !== "view_comercialalerts")
                .map((prev) => ({
                  value: prev.codename,
                  checked: currentPermissions.includes(prev.codename),
                  label:
                    prev.codename === "manage_downloads"
                      ? "Gestión de Descargas"
                      : prev.codename === "manage_mails"
                      ? "Gestión de Correos Electrónicos"
                      : prev.codename === "view_faproreport"
                      ? "Reporte fapro"
                      : prev.codename === "add_companycertificate"
                      ? "Vincular Empresas (Certificado Digital)"
                      : prev.codename === "add_companypassword"
                      ? "Vincular Empresas (RUT y Clave)"
                      : prev.name,
                })),
            ],
            executive: _.compact(currentUser?.executive?.split(",")),
            branch: _.compact(currentUser?.branch?.split(",")),
            group: _.compact(currentUser?.group?.split(",")),
          }}
          onSubmit={handleOnSubmit}
          validationSchema={editAccountSchema}
        >
          {({ isSubmitting, handleSubmit }) => (
            <>
              <DialogTitle
                sx={{
                  bgcolor: "background.terciary",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    width: "100%",
                    height: "100%",
                    bgcolor: "background.terciary",
                  }}
                >
                  <Typography>
                    Datos del usuario: <b>({currentUser?.email})</b>
                  </Typography>
                </Box>
              </DialogTitle>
              <DialogContent
                dividers={true}
                sx={{
                  px: 3,
                  py: 4,
                  bgcolor: "background.terciary",
                }}
                onSubmit={handleSubmit}
              >
                <DialogContentText>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <InputStyled
                      fullWidth
                      disabled={isSubmitting}
                      name="name"
                      label="Nombre(s)"
                      placeholder=""
                      inputProps={{
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                    <InputStyled
                      fullWidth
                      disabled={isSubmitting}
                      name="lastName"
                      label="Apellido(s)"
                      placeholder=""
                      inputProps={{
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />

                    <InputStyled
                      fullWidth
                      disabled={isSubmitting}
                      name="phone_number"
                      label="Teléfono"
                      placeholder=""
                      inputProps={{
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />

                    <Divider />
                    <CheckboxStyled
                      disabled={isSubmitting}
                      name="permissions"
                      label="Facultades de Cuenta:"
                      fullWidth
                      sxFormLabel={{
                        bgcolor: "background.oldClearBlue",
                        width: "100%",
                        textAlign: "center",
                        py: 1,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: "text.primary",
                      }}
                      sxFormGroup={{
                        borderWidth: 1,
                        borderColor: "background.lilacLines",
                        borderStyle: "solid",
                        width: "100%",
                        p: 3,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    />
                    <Divider />
                    <AutoCompleteStyled
                      disabled={isSubmitting}
                      fullWidth
                      freeSolo
                      name={"executive"}
                      label="Filtro de ejecutivo (opcional)"
                      placeholder="Presiona enter para agregar"
                    />
                    <AutoCompleteStyled
                      disabled={isSubmitting}
                      fullWidth
                      freeSolo
                      name={"branch"}
                      label="Filtro de sucursal (opcional)"
                      placeholder="Presiona enter para agregar"
                    />
                    <AutoCompleteStyled
                      disabled={isSubmitting}
                      fullWidth
                      freeSolo
                      name={"group"}
                      label="Filtro de grupo (opcional)"
                      placeholder="Presiona enter para agregar"
                    />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                sx={{
                  bgcolor: "background.terciary",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    bgcolor: "background.terciary",
                    gap: 2,
                    width: "100%",
                    height: "100%",
                    px: 2,
                    py: 1,
                  }}
                >
                  <Button
                    disabled={isSubmitting}
                    color="error"
                    type="button"
                    onClick={() => onShow()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Editar empresa
                  </Button>
                </Box>
              </DialogActions>
            </>
          )}
        </Formik>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: 650,
            bgcolor: "background.terciary",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "90vh",
            height: "90vh",
            overflowY: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </ModalStyled>
  );
};
